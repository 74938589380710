
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import ShowCustomFieldsBlock from '@/components/project/blocks/ShowCustomFieldsBlock.vue'

@Options({
  components: { ShowCustomFieldsBlock },
  emits: ['update:visibilityModel', 'update:customFieldVisibilities'],
  name: 'DataVisibilitySettings',
})
export default class DataVisibilitySettings extends Vue {
  @PropSync('items', { type: Array }) _items!: { label: string; visibilityModel: boolean }[]
  @PropSync('customFieldVisibilities', { type: Array }) _customFieldVisibilities!: string[]
  @PropSync('isReadOnly', { type: Boolean }) _isReadOnly!: boolean
  @PropSync('hasCustomFields', { type: Boolean }) _hasCustomFields!: boolean

  onStatusChange(index: number, value: boolean) {
    this.$emit('update:visibilityModel', { index, value })
  }

  onChangeCustomFieldVisibilities(newValue: string[]) {
    this.$emit('update:customFieldVisibilities', newValue)
  }
}
