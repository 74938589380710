<template>
  <el-dialog
    v-if="!_isLoggedAsClient"
    custom-class="el-dialog--custom"
    v-model="_isShowClientPresetDialog"
    width="1000px"
    top="0"
    :title="_title"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="true"
    @close="handleClose"
  >
    <div>
      {{ _subTitle }}
    </div>
    <div class="my-4">
      <el-checkbox-group v-model="localSelectedClients">
        <div class="flex flex-row flex-wrap px-2 group-name-container">
          <div v-for="client in _clients" :key="client.id" class="w-1/4 py-2">
            <el-checkbox :value="client.id" :label="client.id" :disabled="_isReadOnly">
              {{ client.name }}
            </el-checkbox>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-cancel" @click="cancelSelectClients" :disabled="_isReadOnly">
            {{ $t('barcode_type.cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button type="primary" @click="setSelectedClients(_selectedIndex)" :disabled="_isReadOnly">
            {{ $t('set') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  emits: ['update:isShowClientPresetDialog', 'set-selected-clients', 'cancel-select-clients'],
  name: 'AssignClientDialog',
})
export default class AssignClientDialog extends Vue {
  @PropSync('isReadOnly', { type: Boolean, required: true }) _isReadOnly!: boolean
  @PropSync('isShowClientPresetDialog', { type: Boolean, required: true }) _isShowClientPresetDialog!: boolean
  @PropSync('clients', { type: Array, required: true }) _clients!: Array<{ id: string; name: string }>
  @PropSync('selectedClients', { type: Array, required: true }) _selectedClients!: string[]
  @PropSync('selectedIndex', { type: Number, required: false }) _selectedIndex?: number
  @PropSync('isLoggedAsClient', { type: Boolean, required: true }) _isLoggedAsClient!: boolean
  @PropSync('title', { type: String, required: true }) _title!: string
  @PropSync('subTitle', { type: String, required: true }) _subTitle!: string

  localSelectedClients: string[] = []

  handleClose() {
    this.$emit('update:isShowClientPresetDialog', false)
  }

  setSelectedClients(index: number) {
    if (this._selectedIndex !== undefined) {
      this.$emit('set-selected-clients', { index, clients: this.localSelectedClients })
    } else {
      this.$emit('set-selected-clients', this.localSelectedClients)
    }
  }

  cancelSelectClients() {
    this.$emit('cancel-select-clients')
  }

  @Watch('_selectedClients', { immediate: true, deep: true })
  onSelectedClientsChange() {
    this.localSelectedClients = cloneDeep(this._selectedClients)
  }
}
</script>
