<template>
  <div>
    <div v-for="kv in _trackingPointDatasArr" :key="kv.key" class="mb-3">
      <div>
        <el-checkbox
          :disabled="_isReadOnly"
          @change="onVisibilityChange(kv.key)"
          v-model="_tpTracingVisibilities[kv.key]"
        >
          {{ kv.value.name }} {{ `(${kv.key})` }}
        </el-checkbox>
      </div>
      <div v-if="_tpTracingVisibilities[kv.key]" class="border-l-2 border-gray-500 border-solid pl-2 ml-12">
        <div class="p-1">
          <el-checkbox :disabled="_isReadOnly" v-model="_trackPointTracingVisibilities[kv.key].showLocation">
            {{ $t('barcode_type.location') }}
          </el-checkbox>
        </div>
        <div class="p-1">
          <el-checkbox :disabled="_isReadOnly" v-model="_trackPointTracingVisibilities[kv.key].showTrackedPersonName">
            {{ $t('barcode_type.name_of_person_who_added_tracking') }}
          </el-checkbox>
        </div>
        <div class="p-1">
          <el-checkbox
            :disabled="_isReadOnly"
            @change="onCustomFieldVisibilityChange(kv.key, $event)"
            v-model="_TPShowCustomFieldsModels[kv.key]"
          >
            {{ $t('record_item_setting') }}
          </el-checkbox>
        </div>
        <ShowCustomFieldsBlock
          :disabled="_isReadOnly"
          v-if="_TPShowCustomFieldsModels[kv.key]"
          v-model:customFieldVisibilities="_trackPointTracingVisibilities[kv.key].showCustomFields"
        />
        <div class="p-1">
          <el-checkbox :disabled="_isReadOnly" v-model="_trackPointTracingVisibilities[kv.key].showLinkedBarcodes">
            {{ $t('barcode_type.linked_barcodes') }}
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ITrackPointTracingVisibilities } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import ShowCustomFieldsBlock from '@/components/project/blocks/ShowCustomFieldsBlock.vue'

@Options({
  components: { ShowCustomFieldsBlock },
  emits: ['update:trackPointTracingVisibilitiesChild', 'update:customFieldVisibilityChange'],
})
export default class TrackPointCheckbox extends Vue {
  @PropSync('trackingPointDatasArr', { type: Array }) _trackingPointDatasArr!: {
    key: string
    value: { name: string }
  }[]

  @PropSync('isReadOnly', { type: Boolean }) _isReadOnly!: boolean
  @PropSync('tpTracingVisibilities', { type: Object }) _tpTracingVisibilities!: Record<string, boolean>
  @PropSync('passedIndex', { type: Number }) _passedIndex?: number
  @PropSync('TPShowCustomFieldsModels', { type: Object }) _TPShowCustomFieldsModels!: Record<string, boolean>
  @PropSync('trackPointTracingVisibilities', { type: Object }) _trackPointTracingVisibilities!: Record<
    string,
    ITrackPointTracingVisibilities
  >

  onVisibilityChange(key: string) {
    if (this._passedIndex === undefined) {
      this.$emit('update:trackPointTracingVisibilitiesChild', key)
    }

    this.$emit('update:trackPointTracingVisibilitiesChild', this._passedIndex, key)
  }

  onCustomFieldVisibilityChange(key: string, event: unknown) {
    if (this._passedIndex === undefined) {
      this.$emit('update:customFieldVisibilityChange', key, event)
      return
    }
    this.$emit('update:customFieldVisibilityChange', this._passedIndex, key, event)
  }
}
</script>
