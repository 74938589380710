<template>
  <div class="relative mt-5 p-5 border-b border-gray-400 border-solid py-8">
    <div class="flex" style="place-content: space-between">
      <div v-if="isShowTitle" class="font-bold">
        {{ $t('barcode_type.client_data_display_settings') }}
      </div>
    </div>
    <div class="relative mt-3">
      <el-collapse v-if="syncedModelValue.length !== 0" v-model="activeRecordClientVisibility" accordion>
        <el-collapse-item
          class="border p-5 border-solid border-gray-400 rounded-lg"
          v-for="(clientVisibility, index) in syncedModelValue"
          :key="index"
          :name="`${index + 1}`"
        >
          <template v-slot:title>
            <div class="flex items-center gap-4 w-full mr-4">
              <div class="flex-1">
                <h4>{{ $t('item') + ` ${index + 1}` }}</h4>
              </div>
              <el-button
                @click.stop="onDisplaySettingDelete(index)"
                class="bg-red-min text-white focus:text-white"
                v-if="!isReadOnly"
                :disabled="isReadOnly"
              >
                {{ $t('delete') }}
              </el-button>
            </div>
          </template>
          <div class="client-visibility-wrapper border mt-5 p-5 border-solid border-gray-400 rounded-lg">
            <div v-if="!isReadOnly" class="text-right">
              <el-button type="primary" @click="openAssignClientDialog(index)" :disabled="isReadOnly">
                {{ $t('barcodeImport.assign_client') }}
              </el-button>
            </div>
            <div class="grid grid-cols-5 my-5 gap-4">
              <div class="col-span-2 text-right">{{ $t('barcode_type.basic_info') }}</div>
              <DataVisibilitySettings
                :items="dataVisibilityItems(syncedModelValue[index].activationDataVisibility)"
                :customFieldVisibilities="syncedModelValue[index].activationDataVisibility.showCustomFields"
                :isReadOnly="isReadOnly"
                :hasCustomFields="syncedModelValue[index].activationDataVisibility.hasActivationCustomFields"
                @update:visibilityModel="onVisibilityChange(index, $event)"
                @update:customFieldVisibilities="customFieldVisibilitiesChange(index, $event)"
              />
            </div>

            <div class="grid grid-cols-5 my-5 gap-4">
              <div class="col-span-2 text-right">{{ $t('barcode_type.view_trace_history') }}</div>
              <div class="col-span-3">
                <TrackPointCheckbox
                  :trackingPointDatasArr="trackingPointDatasArr"
                  :tpTracingVisibilities="tpTracingVisibilities[index]"
                  :trackPointTracingVisibilities="clientVisibility.trackPointTracingVisibilities"
                  :TPShowCustomFieldsModels="TPShowCustomFieldsModels"
                  :passedIndex="index"
                  :isReadOnly="isReadOnly"
                  @update:trackPointTracingVisibilitiesChild="onTrackPointTracingVisibilitiesChange"
                  @update:customFieldVisibilityChange="onTPShowCustomFieldsModelsChanged"
                />
              </div>
            </div>
            <!-- <div class="col-span-3 flex flex-wrap gap-3 items-center justify-between">
              <el-button
                @click="onDisplaySettingDelete(index)"
                class="bg-red-min text-white focus:text-white"
                v-if="!isReadOnly"
                :disabled="isReadOnly"
              >
                {{ $t('delete') }}
              </el-button>
            </div> -->
          </div>
        </el-collapse-item>
      </el-collapse>
      <div>
        <ItemNotFound
          iconColor="black"
          background="bg-primary-pure-white"
          class="mb-5"
          :content="$t('permissions.no_data')"
          v-if="syncedModelValue.length === 0"
        />
      </div>
      <div v-if="!isReadOnly" class="absolute add-client-setting">
        <el-button type="primary" @click="onAddClientVisibility()">{{ $t('barcode_type.add_new_rule') }}</el-button>
      </div>
    </div>
    <div class="dialog-area">
      <AssignClientDialog
        :title="$t('barcodeImport.assign_client')"
        :subTitle="$t('select_clients_to_asign_data_visibility')"
        :is-read-only="isReadOnly"
        :is-show-client-preset-dialog="isShowClientPresetDialog"
        :clients="clients"
        :selected-clients="selectedClients"
        :selected-index="selectedIndex"
        :is-logged-as-client="isLoggedAsClient"
        @update:isShowClientPresetDialog="isShowClientPresetDialog = $event"
        @set-selected-clients="setSelectedClients"
        @cancel-select-clients="cancelSelectClients"
      />
    </div>
  </div>
</template>
<script lang="ts">
import AssignClientDialog from '@/components/common/AssignClientDialog.vue'
import DataVisibilitySettings from '@/components/common/DataVisibilitySettings.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import TrackPointCheckbox from '@/components/common/TrackPointCheckbox.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import ShowCustomFieldsBlock from '@/components/project/blocks/ShowCustomFieldsBlock.vue'
import { LOAD_CLIENT_LIST } from '@/store/actions'
import { CLIENT_VISIBILITY_TEMPLATE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IActivationDataVisibility,
  IClientList,
  IClientVisibilities,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'ClientDisplaySettings',
  components: {
    ShowCustomFieldsBlock,
    AssignClientDialog,
    DataVisibilitySettings,
    TrackPointCheckbox,
    ItemNotFound,
  },
})
export default class ClientDisplaySettings extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Boolean, default: false }) readonly isReadOnly?: boolean
  @Prop({ type: Boolean, default: true }) readonly isShowTitle?: boolean
  @Prop({ type: String }) readonly currentBCTypeCode?: string | undefined | null

  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IClientVisibilities[]

  TPShowCustomFieldsModels: Record<string, boolean> = {}

  trackPointVisibilities: Record<string, boolean>[] = []
  isShowClientPresetDialog = false
  selectedClients: string[] = []
  selectedIndex = 0
  activeRecordClientVisibility = ''

  isProcessedSyncedModelValue = false

  get isEditMode() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get projectCode() {
    return this.$store.state.project.projectDetail.mainInfo?.code ?? ''
  }

  @Watch('currentBCTypeCode')
  onChangeCurrentBCTypeCode() {
    if (!this.currentBCTypeCode || !this.syncedModelValue) {
      return
    }
    this.formatSyncModelData()
  }

  get syncedModelValue(): IClientVisibilities[] {
    if (this._syncedModelValue) {
      return this._syncedModelValue
    } else {
      const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
      this._syncedModelValue = [newVisibilityTemplate]
      return this._syncedModelValue
    }
  }

  set syncedModelValue(val: IClientVisibilities[]) {
    if (this._syncedModelValue) {
      this._syncedModelValue = val
    } else {
      const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
      this._syncedModelValue = [newVisibilityTemplate]
    }
  }

  get tpTracingVisibilities() {
    return this.syncedModelValue.map((_, index) => {
      const visibility = {} as Record<string, boolean>
      const visibleTP = Object.keys(this.syncedModelValue[index]?.trackPointTracingVisibilities || {})
      this.trackingPointDatasArr.forEach((tp) => {
        visibility[tp.key] = visibleTP.includes(tp.key)
      })
      return visibility
    })
  }

  fetchClients() {
    if (this.isLoggedAsClient) return
    try {
      this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
    }
  }

  get clients() {
    return (
      [...this.$store.state.client.clients]
        .filter((val: IClientList) => !!val.clientUser)
        .map((c: IClientList) => ({
          id: c.clientUser.id,
          name: c.client.name,
          email: c.clientUser.email,
        })) || []
    )
  }

  @Watch('trackingPointDatasArr', { deep: true })
  onTrackingPointDatasArrChanged() {
    this.formatSyncModelData()
    this.updateTrackPointVisibilities()
  }

  @Watch('syncedModelValue')
  onSyncedModelValueChanged() {
    this.updateTrackPointVisibilities()
    if (!this.isProcessedSyncedModelValue) {
      this.formatSyncModelData()
    }

    this.syncedModelValue.forEach((modelValue) => {
      Object.keys(modelValue.trackPointTracingVisibilities || {}).forEach((key) => {
        this.TPShowCustomFieldsModels[key] = modelValue.trackPointTracingVisibilities[key].showCustomFields.length > 0
      })
    })
  }

  openAssignClientDialog(passedIndex: number) {
    if (this.isReadOnly) return
    this.selectedIndex = passedIndex
    this.selectedClients = this.syncedModelValue[this.selectedIndex].clientIds
    this.isShowClientPresetDialog = true
  }

  updateTrackPointVisibilities() {
    if (this.trackPointVisibilities.length <= 0) {
      return
    }
    this.trackPointVisibilities = this.syncedModelValue.map((item) => {
      const visibility = {} as Record<string, boolean>
      const visibleTP = Object.keys(item.trackPointTracingVisibilities || {})

      this.trackingPointDatasArr.forEach((tp) => {
        visibility[tp.key] = visibleTP.includes(tp.key)
      })

      return visibility
    })
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  setSelectedClients({ index, clients }: { index: number; clients: string[] }) {
    if (this.isReadOnly) return
    this.syncedModelValue[index].clientIds = clients
    this.isShowClientPresetDialog = false
  }

  cancelSelectClients() {
    this.selectedClients = []
    this.isShowClientPresetDialog = false
  }

  formatSyncModelData() {
    if (this.syncedModelValue.length === 0) {
      return
    }
    for (let i = 0; i < this.syncedModelValue.length; i++) {
      if (this.syncedModelValue[i].activationDataVisibility) {
        this.processActivationData(this.syncedModelValue[i])
        this.processTrackingData(this.syncedModelValue[i])
      }
    }
    this.isProcessedSyncedModelValue = true
  }

  processActivationData(clientVisibility: IClientVisibilities) {
    const activationData = clientVisibility.activationDataVisibility
    if (!activationData.showCustomFields) {
      activationData.showCustomFields = []
    }
    activationData.hasActivationCustomFields = activationData.showCustomFields.length > 0
  }

  processTrackingData(clientVisibility: IClientVisibilities) {
    this.trackingPointDatasArr.forEach((tp) => {
      if (clientVisibility.trackPointTracingVisibilities[tp.key]) {
        clientVisibility.trackPointTracingVisibilities[tp.key].showCustomFieldsCheckBoxes =
          clientVisibility.trackPointTracingVisibilities?.[tp.key]?.showCustomFields.length > 0
      }
    })
  }

  onTrackPointTracingVisibilitiesChange(index: number, changeKey: string) {
    if (!this.syncedModelValue[index].trackPointTracingVisibilities[changeKey]) {
      this.syncedModelValue[index].trackPointTracingVisibilities[changeKey] = {
        showLocation: false,
        showTrackedPersonName: false,
        showCustomFields: [],
        showLinkedBarcodes: false,
      }
    } else delete this.syncedModelValue[index].trackPointTracingVisibilities[changeKey]
  }

  checkValueObjectEmpty(obj: IClientVisibilities) {
    return Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  onTPShowCustomFieldsModelsChanged(index: number, key: string, newVal: boolean) {
    if (newVal) return
    this.syncedModelValue[index].trackPointTracingVisibilities[key].showCustomFields = []
  }

  onAddClientVisibility() {
    if (this.isReadOnly) return
    const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
    this.syncedModelValue.push({ ...newVisibilityTemplate })
  }

  onDisplaySettingDelete(index: number) {
    if (this.isReadOnly) return
    this.$confirm(this.$t('confirm_delete_data_display_setting', { content: `Item ${index + 1}` }), this.$t('confirmation'), {
      confirmButtonText: this.$t('remove'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    }).then(() => {
      this.syncedModelValue.splice(index, 1)
    })
  }

  async created() {
    this.fetchClients()
    this.formatSyncModelData()
  }

  dataVisibilityItems(activationDataVisibility: IActivationDataVisibility) {
    return [
      {
        label: this.$t('barcode_type.origin'),
        visibilityModel: activationDataVisibility.showOrigin,
        key: 'showOrigin',
      },
      {
        label: this.$t('barcode_type.destination'),
        visibilityModel: activationDataVisibility.showDestination,
        key: 'showDestination',
      },
      {
        label: this.$t('tracking_number'),
        visibilityModel: activationDataVisibility.showTrackingNumber,
        key: 'showTrackingNumber',
      },
      {
        label: this.$t('barcode_type.external_id'),
        visibilityModel: activationDataVisibility.showExternalId,
        key: 'showExternalId',
      },
      {
        label: this.$t('barcode_type.product_info'),
        visibilityModel: activationDataVisibility.showProduct,
        key: 'showProduct',
      },
      {
        label: this.$t('record_item_setting'),
        visibilityModel: activationDataVisibility.hasActivationCustomFields,
        key: 'showCustomFields',
      },
      {
        label: this.$t('barcode_type.linked_barcodes'),
        visibilityModel: activationDataVisibility.showLinkedBarcodes,
        key: 'showLinkedBarcodes',
      },
      {
        label: this.$t('barcode_type.dimension'),
        visibilityModel: activationDataVisibility.showDimension,
        key: 'showDimension',
      },
    ]
  }

  onVisibilityChange(index: number, { index: visibilityIndex, value }: { index: number; value: boolean }) {
    if (
      this._syncedModelValue &&
      this._syncedModelValue[index] &&
      this._syncedModelValue[index].activationDataVisibility
    ) {
      const key = this.dataVisibilityItems(this._syncedModelValue[index].activationDataVisibility)[visibilityIndex]
        .key as keyof IActivationDataVisibility

      if (key === 'showCustomFields') {
        this._syncedModelValue[index].activationDataVisibility.hasActivationCustomFields = value
      } else {
        this._syncedModelValue[index].activationDataVisibility[key] = value
      }
    }
    this.$emit('update:modelValue', this._syncedModelValue)
  }

  customFieldVisibilitiesChange(index: number, visibleFields: string[]) {
    if (this._syncedModelValue) {
      this._syncedModelValue[index].activationDataVisibility.showCustomFields = visibleFields
      this.$emit('update:modelValue', this._syncedModelValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-client-setting {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}

.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 1000px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}
</style>
