
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  emits: ['update:isShowClientPresetDialog', 'set-selected-clients', 'cancel-select-clients'],
  name: 'AssignClientDialog',
})
export default class AssignClientDialog extends Vue {
  @PropSync('isReadOnly', { type: Boolean, required: true }) _isReadOnly!: boolean
  @PropSync('isShowClientPresetDialog', { type: Boolean, required: true }) _isShowClientPresetDialog!: boolean
  @PropSync('clients', { type: Array, required: true }) _clients!: Array<{ id: string; name: string }>
  @PropSync('selectedClients', { type: Array, required: true }) _selectedClients!: string[]
  @PropSync('selectedIndex', { type: Number, required: false }) _selectedIndex?: number
  @PropSync('isLoggedAsClient', { type: Boolean, required: true }) _isLoggedAsClient!: boolean
  @PropSync('title', { type: String, required: true }) _title!: string
  @PropSync('subTitle', { type: String, required: true }) _subTitle!: string

  localSelectedClients: string[] = []

  handleClose() {
    this.$emit('update:isShowClientPresetDialog', false)
  }

  setSelectedClients(index: number) {
    if (this._selectedIndex !== undefined) {
      this.$emit('set-selected-clients', { index, clients: this.localSelectedClients })
    } else {
      this.$emit('set-selected-clients', this.localSelectedClients)
    }
  }

  cancelSelectClients() {
    this.$emit('cancel-select-clients')
  }

  @Watch('_selectedClients', { immediate: true, deep: true })
  onSelectedClientsChange() {
    this.localSelectedClients = cloneDeep(this._selectedClients)
  }
}
