
import { ITrackPointTracingVisibilities } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import ShowCustomFieldsBlock from '@/components/project/blocks/ShowCustomFieldsBlock.vue'

@Options({
  components: { ShowCustomFieldsBlock },
  emits: ['update:trackPointTracingVisibilitiesChild', 'update:customFieldVisibilityChange'],
})
export default class TrackPointCheckbox extends Vue {
  @PropSync('trackingPointDatasArr', { type: Array }) _trackingPointDatasArr!: {
    key: string
    value: { name: string }
  }[]

  @PropSync('isReadOnly', { type: Boolean }) _isReadOnly!: boolean
  @PropSync('tpTracingVisibilities', { type: Object }) _tpTracingVisibilities!: Record<string, boolean>
  @PropSync('passedIndex', { type: Number }) _passedIndex?: number
  @PropSync('TPShowCustomFieldsModels', { type: Object }) _TPShowCustomFieldsModels!: Record<string, boolean>
  @PropSync('trackPointTracingVisibilities', { type: Object }) _trackPointTracingVisibilities!: Record<
    string,
    ITrackPointTracingVisibilities
  >

  onVisibilityChange(key: string) {
    if (this._passedIndex === undefined) {
      this.$emit('update:trackPointTracingVisibilitiesChild', key)
    }

    this.$emit('update:trackPointTracingVisibilitiesChild', this._passedIndex, key)
  }

  onCustomFieldVisibilityChange(key: string, event: unknown) {
    if (this._passedIndex === undefined) {
      this.$emit('update:customFieldVisibilityChange', key, event)
      return
    }
    this.$emit('update:customFieldVisibilityChange', this._passedIndex, key, event)
  }
}
