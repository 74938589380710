<template>
  <div class="col-span-3">
    <div v-for="(item, index) in _items" :key="index" class="mb-3">
      <el-checkbox
        v-model="item.visibilityModel"
        :disabled="_isReadOnly"
        @change="(value) => onStatusChange(index, value)"
      >
        {{ item.label }}
      </el-checkbox>
    </div>
    <ShowCustomFieldsBlock
      v-if="_hasCustomFields"
      :disabled="_isReadOnly"
      v-model:customFieldVisibilities="_customFieldVisibilities"
      @update:newValue="onChangeCustomFieldVisibilities"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import ShowCustomFieldsBlock from '@/components/project/blocks/ShowCustomFieldsBlock.vue'

@Options({
  components: { ShowCustomFieldsBlock },
  emits: ['update:visibilityModel', 'update:customFieldVisibilities'],
  name: 'DataVisibilitySettings',
})
export default class DataVisibilitySettings extends Vue {
  @PropSync('items', { type: Array }) _items!: { label: string; visibilityModel: boolean }[]
  @PropSync('customFieldVisibilities', { type: Array }) _customFieldVisibilities!: string[]
  @PropSync('isReadOnly', { type: Boolean }) _isReadOnly!: boolean
  @PropSync('hasCustomFields', { type: Boolean }) _hasCustomFields!: boolean

  onStatusChange(index: number, value: boolean) {
    this.$emit('update:visibilityModel', { index, value })
  }

  onChangeCustomFieldVisibilities(newValue: string[]) {
    this.$emit('update:customFieldVisibilities', newValue)
  }
}
</script>
